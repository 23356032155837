@font-face {
  font-family: "Regular1";
  src: url("./Archivo-Regular.ttf");
}
@font-face {
  font-family: "Regular2";
  src: url("./Archivo-Regular.woff");
}
@font-face {
  font-family: "Regular3";
  src: url("./Archivo-Regular.woff2");
}

html {
  font-family: "Regular1";
}
