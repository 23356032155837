* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: black;
  color: white;
  font-size: 18px;
  -ms-overflow-style: none /* IE and Edge */;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: white;
}
.cursorArrow {
  cursor: url('./pictures/arrow.svg'), auto;
}
.cursorArrowL {
  cursor: url('./pictures/arrowL.svg'), auto;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
}
